import { ComponentProps, FC, useEffect, useState } from 'react'
import { useAmityFile } from 'amity/lib'
import { useAxios } from 'api/lib'
import { Link } from 'ui/components/navigation'
import { formatFullDate } from 'ui/src/lib/formatters'
import { GroupAvatar } from '../Groups'

export interface GroupCardProps extends ComponentProps<'div'> {
  readonly group: Amity.Community
  readonly urlPrefix?: string
}

const getGroupUrl = (communityId: string, prefix?: string) => `${prefix}/groups/${communityId}`
const getInvestmentUrl = (communityId: string | number, prefix?: string) => `${prefix}/investments/${communityId}`

export const GroupCard: FC<GroupCardProps> = ({ group, urlPrefix = '', className, ...props }) => {
  const file = useAmityFile(group.avatarFileId)
  const [updatedAt] = useState(formatFullDate(group.updatedAt))
  const [href, setHref] = useState(getGroupUrl(group.communityId, urlPrefix))

  const { data: investmentOpportunityId } = useAxios<number | null>(
    `/api/investment-opportunities/groupId/${group.communityId}`,
  )

  useEffect(() => {
    investmentOpportunityId
      ? setHref(getInvestmentUrl(investmentOpportunityId, urlPrefix))
      : setHref(getGroupUrl(group.communityId, urlPrefix))
  }, [group?.isPublic, investmentOpportunityId])

  return (
    <Link className={className} href={href}>
      <div className="flex gap-4" {...props}>
        <GroupAvatar
          className="h-11 w-11 shrink-0 self-start"
          fileUrl={file?.fileUrl}
          displayName={group.displayName}
        />

        <div className="flex flex-col">
          <div className="text-deep-teal-500 basic-text-base leading-1 font-semibold">
            {group.displayName}
          </div>

          <div className="text-deep-teal-300 basic-text-sm leading-1 font-normal italic">Updated at {updatedAt}</div>
        </div>
      </div>
    </Link>
  )
}
