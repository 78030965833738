import { FC, ReactElement, useContext } from 'react'
import { PlatformComponentsContext } from './PlatformComponentsContext'

export type PlatformPromptProps = {
  when: boolean
  children: React.ReactNode | PlatformPromptChildren
}

type PlatformPromptChildren = ({
  cancelNavigation,
  proceedWithoutAction,
  executeAndProceed,
}: {
  cancelNavigation: () => void
  proceedWithoutAction: (callback?: () => void) => void
  executeAndProceed: (callback: () => Promise<void>) => Promise<void>
}) => ReactElement

export const PlatformPrompt: FC<PlatformPromptProps> = (props) => {
  const { prompt: Component } = useContext(PlatformComponentsContext)
  return <Component {...props} />
}
