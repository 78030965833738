import type {
  CatalogType as CatalogTypeDB,
  InviteAccreditation as InviteAccreditationDB,
  InviteReference as InviteReferenceDB,
  InviteStatus as InviteStatusDB,
  IoiStatus as IoiStatusDB,
  NominationStatus as NominationStatusDB,
  PermissionKey as PermissionKeyDB,
  Role as RoleDB,
  SelfAccreditation as SelfAccreditationDB,
  SubDocStatus as SubDocStatusDB,
  SubscriptionStatus as SubscriptionStatusDB,
  UserSocialProfileActivationStatus as UserSocialProfileActivationStatusDB,
  UserSocialProfileRestrictions as UserSocialProfileRestrictionsDB,
  UserStatus as UserStatusDB,
} from 'database'

export const CatalogType = {
  PLANNING_GOALS: 'PLANNING_GOALS',
  GROSS_ANNUAL_HOUSEHOLD_INCOME: 'GROSS_ANNUAL_HOUSEHOLD_INCOME',
} as const satisfies typeof CatalogTypeDB
export type CatalogTypeEnum = keyof typeof CatalogType

export const InviteAccreditation = {
  MORE_THEN_5000K: 'MORE_THEN_5000K',
  MORE_THEN_2500K_LESS_THEN_5000K: 'MORE_THEN_2500K_LESS_THEN_5000K',
  LESS_THEN_2500K: 'LESS_THEN_2500K',
} as const satisfies typeof InviteAccreditationDB
export type InviteAccreditationEnum = keyof typeof InviteAccreditation

export const InviteReference = {
  MEMBER: 'MEMBER',
  WORD_OF_MOUTH: 'WORD_OF_MOUTH',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
  OTHER: 'OTHER',
} as const satisfies typeof InviteReferenceDB
export type InviteReferenceEnum = keyof typeof InviteReference

export const InviteStatus = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  USED: 'USED',
  ARCHIVED: 'ARCHIVED',
} as const satisfies typeof InviteStatusDB
export type InviteStatusEnum = keyof typeof InviteStatus

export const IoiStatus = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
} as const satisfies typeof IoiStatusDB
export type IoiStatusEnum = keyof typeof IoiStatus

export const NominationStatus = {
  SENT: 'SENT',
  ACCEPTED: 'ACCEPTED',
  PROSPECT_CREATED: 'PROSPECT_CREATED',
  DUPLICATE: 'DUPLICATE',
} as const satisfies typeof NominationStatusDB
export type NominationStatusEnum = keyof typeof NominationStatus

export const PermissionKey = {
  NOMINATIONS_ALLOWED: 'NOMINATIONS_ALLOWED',
  NOMINATIONS_LIMIT: 'NOMINATIONS_LIMIT',
} as const satisfies typeof PermissionKeyDB
export type PermissionKeyEnum = keyof typeof PermissionKey

export const Role = {
  ADMIN: 'ADMIN',
  SIGNUP: 'SIGNUP',
  INVESTOR: 'INVESTOR',
} as const satisfies typeof RoleDB
export type RoleEnum = keyof typeof Role

export const SelfAccreditation = {
  AI: 'AI',
  QP: 'QP',
  EI: 'EI',
} as const satisfies typeof SelfAccreditationDB
export type SelfAccreditationEnum = keyof typeof SelfAccreditation

export const SubDocStatus = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
} as const satisfies typeof SubDocStatusDB
export type SubDocStatusEnum = keyof typeof SubDocStatus

export const SubscriptionStatus = {
  INCOMPLETE: 'INCOMPLETE',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
} as const satisfies typeof SubscriptionStatusDB
export type SubscriptionStatusEnum = keyof typeof SubscriptionStatus

export const UserSocialProfileActivationStatus = {
  PENDING: 'PENDING',
  ACTIVATED: 'ACTIVATED',
} as const satisfies typeof UserSocialProfileActivationStatusDB
export type UserSocialProfileActivationStatusEnum = keyof typeof UserSocialProfileActivationStatus

export const UserSocialProfileRestrictions = {
  NONE: 'NONE',
  RESTRICTED: 'RESTRICTED',
} as const satisfies typeof UserSocialProfileRestrictionsDB
export type UserSocialProfileRestrictionsEnum = keyof typeof UserSocialProfileRestrictions

export const UserStatus = {
  CREATED: 'CREATED',
  PROFILE_COMPLETED: 'PROFILE_COMPLETED',
  KYC_REQUESTED: 'KYC_REQUESTED',
  KYC_FAILED: 'KYC_FAILED',
  KYC_APPROVED: 'KYC_APPROVED',
  SUBSCRIBED: 'SUBSCRIBED',
} as const satisfies typeof UserStatusDB
export type UserStatusEnum = keyof typeof UserStatus
