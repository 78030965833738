import mixpanelPlugin from '@analytics/mixpanel'
import Analytics, { AnalyticsInstance } from 'analytics'
import jsEventsTrack from './plugins/js-events-track'

const isBrowser = typeof window !== 'undefined'

const analytics: AnalyticsInstance | null = isBrowser
  ? Analytics({
      app: 'meetperry',
      plugins: [
        mixpanelPlugin({
          token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
          options: {
            api_host: `${process.env.NEXT_PUBLIC_API_HOST}/api/analytics`,
          },
          // Workaround for Safari to force https usage in local development.
          // Inside of a plugin code the same value is hardcoded but with
          // protocol determined based on host. So for localhost it will be http
          // and it does not work in the safari and simulator.
          // Can be left as is, because internally the plugin is using
          // exactly the same hardcoded url. The only difference it switches
          // between http and https based on the host.
          customScriptSrc: 'https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js',
        }),
        jsEventsTrack(),
      ],
    })
  : null

export default analytics
