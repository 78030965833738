import { FC, ComponentProps, useState, useEffect, Fragment } from 'react'
import { useRouter } from 'next/router'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { NextLink } from '../../../navigation/NextLink'

export interface NavigationItem {
  readonly name: string
  readonly href?: string
  readonly regex?: RegExp
  readonly target?: string
  readonly current?: boolean
  readonly children?: NavigationItem[]
  readonly onClick?: () => void
}

interface NavProps extends ComponentProps<'div'> {
  readonly navigation?: NavigationItem[]
}

interface NavMenuProps extends ComponentProps<'div'> {
  readonly item: NavigationItem
}

interface PlainNavItemsProps {
  readonly items: NavigationItem[]
}

interface PlainNavItemProps {
  readonly item: NavigationItem
}

export const Nav: FC<NavProps> = ({ navigation, className, ...props }) => {
  const nav = useGlobalNavigation(navigation)
  return (
    <div className={clsx('flex h-full space-x-6', className)} {...props}>
      {nav.map((item) =>
        item.children?.length ? (
          <NavMenu item={item} key={item.name} />
        ) : (
          <NextLink
            target={item.target}
            key={item.name}
            href={item.href}
            className={clsx(
              'inline-flex items-center px-1 text-sm font-medium',
              item.current ? 'text-white' : 'text-deep-teal-100 hover:text-white',
            )}
          >
            <span
              className={clsx(
                'border-b-2',
                item.current ? '-mb-2 border-orange-500 pb-2' : 'border-transparent',
              )}
            >
              {item.name}
            </span>
          </NextLink>
        ),
      )}
    </div>
  )
}

const NavMenu: FC<NavMenuProps> = ({ item }) => {
  return (
    <div className="relative">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button
              className={clsx(
                'h-full whitespace-nowrap px-1 text-sm font-medium leading-5',
                'flex flex-nowrap items-center',
                'focus:outline-none', // Temporary remove focus outline

                { 'text-deep-teal-100 hover:text-white': !item.current },
                { 'text-white': open },
                { 'text-white': item.current },
              )}
            >
              <span
                className={clsx(
                  'flex items-center border-b-2',
                  item.current ? '-mb-2 border-orange-500 pb-2' : 'border-transparent',
                )}
              >
                <span>{item.name}</span>
                <ChevronDownIcon
                  className={clsx(
                    'h-5 w-5',
                    { 'text-deep-teal-100': !item.current },
                    { 'text-white': item.current },
                    { 'rotate-180': open },
                  )}
                />
              </span>
            </Menu.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="absolute top-0 left-0 z-50 mt-16 overflow-hidden rounded-b bg-white py-1 shadow-lg focus:outline-none"
                static
              >
                {item.children.map((child) => (
                  <Menu.Item key={child.name}>
                    {({ active }) => (
                      <NextLink
                        target={child.target}
                        href={child.href}
                        className={clsx(
                          'block w-full whitespace-nowrap border-l-4 p-2 pr-4 pl-3',
                          'text-sm leading-5',
                          !active && !child.current && 'text-deep-teal-800 border-transparent',
                          active && !child.current && 'bg-taupe-300',
                          active && 'text-text-deep-teal-800  border-transparent',
                          child.current && 'border-orange-500 bg-orange-50 text-orange-800',
                        )}
                      >
                        {child.name}
                      </NextLink>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  )
}

export const PlainNav: FC<NavProps> = ({ navigation, className, ...props }) => {
  const nav = useGlobalNavigation(navigation)
  return (
    <div className={clsx('mx-auto max-w-5xl space-y-1 pt-2 pb-3', className)} {...props}>
      <PlainNavItems items={nav} />
    </div>
  )
}

const PlainNavItems: FC<PlainNavItemsProps> = ({ items }) => {
  return (
    <>
      {items.map((item) => (
        <PlainNavItem item={item} key={item.name} />
      ))}
    </>
  )
}

const PlainNavItem: FC<PlainNavItemProps> = ({ item }) => {
  return item.children?.length ? (
    <PlainNavItems items={item.children} />
  ) : (
    <NextLink
      target={item.target}
      key={item.name}
      href={item.href}
      aria-current={item.current ? 'page' : undefined}
      className={clsx(
        'relative block py-2.5 px-4 text-base font-medium  sm:px-6 ',
        item.current
          ? clsx(
              'bg-deep-teal-600 text-white',
              'before:absolute before:inset-0 before:block before:w-1 before:bg-orange-500',
            )
          : 'text-deep-teal-100 hover:bg-deep-teal-600 hover:text-white',
      )}
    >
      {item.name}
    </NextLink>
  )
}

const useGlobalNavigation = (navigation: NavigationItem[] = []): NavigationItem[] => {
  const router = useRouter()
  const [currentNav, setCurrentNav] = useState(navigation)

  useEffect(() => {
    setCurrentNav(navigation.map((item) => checkIsNavItemCurrent(item, router.pathname)))
  }, [router.pathname, JSON.stringify(navigation)])

  return currentNav
}

const checkIsNavItemCurrent = (item: NavigationItem, pathname: string) => {
  const mappedChildren = item.children?.map((child) => checkIsNavItemCurrent(child, pathname))
  const mappedItem = {
    ...item,
    current:
      (item.regex ? item.regex.test(pathname) : pathname.startsWith(item.href)) ||
      mappedChildren?.some((child: NavigationItem) => child.current),
    children: mappedChildren,
  }
  return mappedItem
}
