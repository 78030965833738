import {
  ChangeEventHandler,
  ClipboardEventHandler,
  ComponentPropsWithRef,
  FC,
  forwardRef,
  KeyboardEventHandler,
  useState,
} from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'
import clsx from 'clsx'
import { uniq } from 'lodash'
import { PasteEventHandler } from './parsers'
import Tag from './Tag'
import { Error, Label } from '../Field'

export interface TagFieldProps extends Omit<TagInputProps, 'onChange' | 'value'> {
  readonly className?: string
  readonly control: Control<any>
}

// eslint-disable-next-line react/display-name
export const TagField: FC<TagFieldProps> = forwardRef(({ name, control, ...props }, ref) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <TagInput
            {...props}
            ref={ref}
            name={field.name}
            defaultValue={field.value}
            onChange={(tags) => field.onChange(tags)}
            error={fieldState.error}
          />
        )
      }}
    />
  )
})

export interface TagInputProps extends Pick<ComponentPropsWithRef<'input'>, 'ref' | 'className'> {
  readonly name: string
  readonly label?: string
  readonly placeholder?: string
  readonly disabled?: boolean
  readonly onChange?: (value: string[]) => void
  readonly submitKeyCodes?: string[]
  readonly removeOnBackspace?: boolean
  readonly defaultValue?: string[]
  readonly error?: FieldError
  readonly value?: string[]
  readonly onPasteHandler?: PasteEventHandler

  /**
   * Validates a value before turning it into a tag.
   * Returns a string representing an error if the value is invalid.
   * Otherwise returns null.
   */
  readonly validate?: (value: string) => string | null
}

// eslint-disable-next-line react/display-name
const TagInput: FC<TagInputProps> = forwardRef(
  (
    {
      name,
      label,
      placeholder,
      disabled,
      onChange,
      validate = () => null,
      submitKeyCodes = ['Enter'],
      removeOnBackspace = true,
      defaultValue = [],
      error,
      className,
      onPasteHandler,
      value: tags = defaultValue,
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState<string>('')
    const [validationError, setValidationError] = useState<string | null>()

    const aggregatedError = validationError || error?.message

    const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      setInputValue(e.target.value)

      // Hide validation error (if any) when the user clears the input field.
      if (e.target?.value?.length === 0) {
        setValidationError(null)
      }
    }

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (submitKeyCodes.includes(e.code)) {
        // Prevent bubbling of the event, otherwise it will immediately submit an enclosing form.
        e.preventDefault()
        addTag()
      }

      if (removeOnBackspace && e.code === 'Backspace') {
        if (inputValue?.length === 0 && tags?.length > 0) {
          onChange(tags.slice(0, tags.length - 1))
        }
      }
    }

    const addTag = () => {
      if (inputValue?.length > 0) {
        const validationError = validate(inputValue)

        if (validationError) {
          setValidationError(validationError)
        } else if (tags.includes(inputValue?.trim())) {
          setValidationError('Duplicated entries are not allowed')
        } else {
          onChange([...tags, inputValue?.trim()])
          setInputValue('')
          setValidationError(null)
        }
      }
    }

    const removeTag = (tagToRemove: string) => {
      onChange(tags.filter((tag) => tag !== tagToRemove))
    }

    const handleOnPaste: ClipboardEventHandler = (e) => {
      if (onPasteHandler) {
        const rawString = e.clipboardData?.getData('text')
        const parsedTags = onPasteHandler(rawString)

        if (parsedTags?.length > 0) {
          const newTags = parsedTags.filter((t) => !tags.includes(t))
          onChange([...tags, ...uniq(newTags)])
        }

        e.preventDefault()
      }
    }

    return (
      <div className={clsx(disabled ? 'cursor-not-allowed opacity-25' : '', className)}>
        {label && (
          <Label name={name} className="mb-1">
            {label}
          </Label>
        )}

        <div
          className={clsx(
            'block w-full', // Alignment
            'rounded-md shadow-sm', // Border
            'sm:text-sm', // Text
            'border bg-white outline-2 outline-offset-2',
            'px-1 py-1',
            'flex flex-wrap items-center gap-1 overflow-auto',
            aggregatedError
              ? [
                  'appearance-none bg-none', // suppressing native element styling (select, number, etc.)
                  '!border-pure-red !hover:border-pure-red !focus-within:border-pure-red',
                ]
              : 'border-taupe-600 hover:border-taupe-700 focus-within:!border-navy-500',
          )}
        >
          {tags.map((tag) => (
            <Tag key={tag} label={tag} disabled={disabled} onRemove={() => removeTag(tag)} />
          ))}

          <input
            id={name}
            name={name}
            type="text"
            onKeyDown={handleKeyDown}
            onChange={handleOnChange}
            onBlur={addTag}
            onPaste={handleOnPaste}
            value={inputValue}
            placeholder={placeholder}
            disabled={disabled}
            className={clsx(
              'inline-block', // Alignment
              'rounded-md', // shadow-sm', // Border
              'sm:text-sm', // Text
              'border-0 bg-transparent focus:ring-0',
              'p-1',
              'w-auto grow',
            )}
            ref={ref}
          />
        </div>

        <Error className="mt-1 h-5">{aggregatedError}</Error>
      </div>
    )
  },
)
