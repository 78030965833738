import { Children, cloneElement, FC, HTMLAttributes, useEffect, useState } from 'react'
import clsx from 'clsx'

export const Tabs: FC<HTMLAttributes<HTMLDivElement> & { onSelected: (index: number) => void }> = ({
  className,
  children,
  onSelected,
}) => {
  const [selected, setSelected] = useState(0)
  useEffect(() => onSelected(selected), [selected])
  return (
    <div className={clsx(className, 'border-deep-teal-100 border-b')}>
      <nav
        className="no-scrollbar relative -mb-px flex space-x-8 overflow-x-scroll scroll-smooth"
        aria-label="Tabs"
      >
        {Children.map(children, (child, index) => {
          return cloneElement(child as any, {
            index,
            selected: selected == index,
            onSelect: setSelected,
          })
        })}
      </nav>
    </div>
  )
}

export const Tab: FC<{
  index?: number
  title: string
  selected?: boolean
  onSelect?: (index: number) => void
}> = ({ index, title, selected, onSelect }) => {
  return (
    <a
      role="tab"
      onClick={(event) => (onSelect(index), scrollToElement(event.target as HTMLElement))}
      className={clsx(
        selected
          ? 'border-orange-500 text-orange-600'
          : 'text-deep-teal-400 hover:text-deep-teal-500 hover:border-deep-teal-200 border-transparent',
        'cursor-pointer whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
      )}
      aria-selected={selected}
    >
      {title}
    </a>
  )
}

const scrollToElement = (element: HTMLElement) => {
  const parentElementWidth = element.parentElement?.offsetWidth || 0
  const elementWidth = element?.offsetWidth || 0
  const offset = (parentElementWidth - elementWidth) / 2

  element.parentElement?.scrollTo &&
    element.parentElement.scrollTo({
      behavior: 'smooth',
      left: element ? element.offsetLeft - offset : 0,
    })
}
