import { FC, HTMLAttributes } from 'react'
import clsx from 'clsx'

const Footer: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
  const navigation = [
    {
      name: 'Terms and Conditions',
      href: '/terms.pdf',
    },
    {
      name: 'Privacy Policy',
      href: '/privacy.pdf',
    },
  ]

  return (
    <footer className={clsx('bg-taupe-400', className)} {...props}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="divide-y divide-taupe-600 md:divide-y-0 md:flex md:items-center md:justify-between">
          <div className="justify-around flex py-12 space-x-6 md:justify-center md:order-2 md:py-8">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                rel="noreferrer"
                target="_blank"
                className="text-taupe-700 hover:text-taupe-800"
              >
                <span>{item.name}</span>
              </a>
            ))}
          </div>
          <div className="py-8 md:order-1">
            <p className="text-center text-base text-taupe-700">
              &copy; 2022 meetperry LLC. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
