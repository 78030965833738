import { ComponentProps, FC } from 'react'
import { EventWithCalendarStartDate } from 'api/dto'
import clsx from 'clsx'
import { isAfter, parseISO } from 'date-fns'
import { LDFlag, useFlag } from 'launchdarkly'
import { Card, MarkdownProse } from 'ui/components/content'
import { Link } from 'ui/components/navigation'
import { AddToCalendarButton } from 'ui/views/events/Event/AddToCalendarButton'
import { useEventCardDate } from './useEventCardDate'

interface EventProps extends ComponentProps<'div'> {
  readonly event: EventWithCalendarStartDate
}

const EventCard: FC<EventProps> = ({ event, className, ...props }) => {
  const eventDate = useEventCardDate(event)

  const enableAddEventToCalendar = useFlag(LDFlag.EnableAddEventToCalendar)

  const eventStartDateTime = parseISO(event.googleEventStartDate)

  const displayAddToCalendarButton =
    enableAddEventToCalendar &&
    event.status === 'upcoming' &&
    event.googleEventStartDate &&
    isAfter(eventStartDateTime, new Date())

  return (
    <Link href={`/events/${event.id}`} className="group">
      <Card className={clsx('cursor-pointer group-hover:shadow-xl', className)} {...props}>
        {/* Mobile layout */}
        <div className="flex flex-wrap items-start gap-4 lg:hidden">
          <Avatar className="shrink-0" src={event.image?.url} />
          <LocationAndDate location={event.location} date={eventDate} isoDate={event.eventDate} />
          <div className="w-full space-y-2">
            <Title title={event.title} subtitle={event.subTitle} />
            <Description description={event.description} />
            {displayAddToCalendarButton && (
              <AddToCalendarButton eventId={event.id} size="md" className="w-full" />
            )}
          </div>
        </div>
        {/* Desktop layout */}
        <div className="hidden gap-4 lg:flex">
          <Avatar className="shrink-0" src={event.image?.url} />
          <div className="w-full space-y-2">
            <div className="flex w-full items-start justify-between gap-4">
              <Title className="shrink" title={event.title} subtitle={event.subTitle} />
              <LocationAndDate className="shrink-0" location={event.location} date={eventDate} />
            </div>
            <div className="flex w-full items-start justify-between gap-4">
              <Description description={event.description} />
              {displayAddToCalendarButton && (
                <AddToCalendarButton eventId={event.id} size="sm" className="min-w-fit" />
              )}
            </div>
          </div>
        </div>
      </Card>
    </Link>
  )
}

interface AvatarProps extends ComponentProps<'div'> {
  src: string
}

const Avatar: FC<AvatarProps> = ({ className, src, ...props }) => {
  return (
    <div
      className={clsx('flex h-[72px] w-[72px] items-center justify-center', className)}
      {...props}
    >
      <img className="h-full w-full shrink-0 rounded object-cover object-left-top" src={src} />
    </div>
  )
}

interface LocationAndDateProps extends ComponentProps<'div'> {
  location: string
  date: string
  isoDate?: string
}

const LocationAndDate: FC<LocationAndDateProps> = ({
  location,
  date,
  isoDate,
  className,
  ...props
}) => {
  return (
    <div
      className={clsx(
        'gap-1 border-l-2 border-orange-500 pl-2 lg:flex lg:items-center lg:justify-end lg:gap-4',
        className,
      )}
      {...props}
    >
      {location && (
        <div className="text-deep-teal-500 text-xs font-medium leading-4">{location}</div>
      )}
      <time className="text-deep-teal-300 text-sm italic leading-5" dateTime={isoDate}>
        {date}
      </time>
    </div>
  )
}

interface TitleProps extends ComponentProps<'div'> {
  title: string
  subtitle?: string
}

const Title: FC<TitleProps> = ({ className, title, subtitle, ...props }) => {
  return (
    <div className={clsx('space-y-1', className)} {...props}>
      <h2 className="text-deep-teal-500 font-semibold leading-5 group-hover:text-orange-600 group-focus:text-orange-700">
        {title}
      </h2>
      {subtitle && <h3 className="text-deep-teal-400 text-sm leading-5">{subtitle}</h3>}
    </div>
  )
}

interface DescriptionProps extends ComponentProps<'p'> {
  description?: string
}

const Description: FC<DescriptionProps> = ({ className, description, ...props }) => {
  return description ? (
    <MarkdownProse
      className={clsx('prose-p:text-sm text-deep-teal-400', className)}
      markdown={description}
      {...props}
    />
  ) : (
    <p className={clsx('text-deep-teal-400 text-sm', className)} {...props}>
      No information yet. Please come back later.
    </p>
  )
}

export default EventCard
