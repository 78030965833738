import { useEffect } from 'react'
import { User } from 'api/dto'
import { useSession } from 'auth'
import { RouterContext, RouterEvents, useRouter } from 'ui/components/navigation'
import { analytics } from 'ui/lib/analytics'

const init = (user: User, router: RouterContext) => {
  analytics.identify(user.id.toString(), {
    firstName: user.firstName,
    lastName: user.lastName,
  })

  // Fire initial page view
  analytics.page()
  // Fire page views on routing
  router.events.on('navigated', handleRouteChange)
}

const reset = (router: RouterContext) => {
  analytics.reset()
  router.events.off('navigated', handleRouteChange)
}

const handleRouteChange = (events: RouterEvents) => {
  const {
    context: { pathname: path },
  } = events

  // We need to wrap it in a rAF to ensure the correct data is sent to Segment
  // https://github.com/zeit/next.js/issues/6025
  if (window?.requestAnimationFrame) {
    window.requestAnimationFrame(() => {
      analytics.page({ path })
    })
  } else {
    analytics.page({ path })
  }
}

const useAnalytics = (): void => {
  const { user } = useSession()
  const router = useRouter()

  useEffect(() => {
    if (user?.id) {
      init(user, router)
    } else if (analytics?.user()?.userId) {
      reset(router)
    }

    return () => reset(router)
  }, [user?.id])
}

export default useAnalytics
