import { FC, useState } from 'react'
import { FieldError, Control, Controller } from 'react-hook-form'
import clsx from 'clsx'
import { TagAutocompleteInputProps, TagAutocompleteInput } from './TagAutocompleteInput'
import { Label, Error } from '../Field'

export interface TagAutocompleteFieldProps
  extends Omit<TagAutocompleteInputProps, 'onSelectedTagsChange' | 'selectedTags' | 'error'> {
  readonly className?: string
  readonly name: string
  readonly label: string | JSX.Element
  readonly error?: FieldError
  readonly hideError?: boolean
  readonly control: Control<any>
}

const TagAutocompleteField: FC<TagAutocompleteFieldProps> = ({
  className,
  disabled,
  name,
  label,
  error,
  hideError,
  control,
  ...props
}) => {
  const [inputValidationError, setInputValidationError] = useState<string | null>()

  const aggregatedError = inputValidationError || error?.message

  return (
    <div className={clsx({ 'opacity-25': disabled }, className)}>
      {label && <Label name={name}>{label}</Label>}

      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, name } }) => {
          return (
            <TagAutocompleteInput
              name={name}
              selectedTags={value as any}
              onSelectedTagsChange={onChange}
              inputValidationError={inputValidationError}
              selectedTagsValidationsError={error?.message}
              setInputValidationError={setInputValidationError}
              {...props}
            />
          )
        }}
      />

      {!hideError && (
        <Error className="mt-1 h-5" id={`${name}-error`} data-testid={`${name}-error`}>
          {aggregatedError}
        </Error>
      )}
    </div>
  )
}

export default TagAutocompleteField
