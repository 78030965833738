import { Children, cloneElement, FC, forwardRef, InputHTMLAttributes, isValidElement } from 'react'
import { FieldError } from 'react-hook-form'
import clsx from 'clsx'
import Error from '../Field/Error'
import Label from '../Field/Label'

export interface RadioGroupProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> {
  readonly value?: string | number | boolean | readonly string[]
  readonly label?: string
  readonly error?: Pick<FieldError, 'message'>
}

// eslint-disable-next-line react/display-name
export const RadioGroup = forwardRef<any, RadioGroupProps>(
  ({ name, label, className, children, error, disabled, onChange, onBlur, ...props }, ref) => {
    const childrenWithProps = Children.map(children, (child) => {
      // Checking isValidElement is the safe way
      // and avoids a typescript error too.
      if (isValidElement(child)) {
        return cloneElement<any>(child, {
          disabled: disabled || child.props.disabled,
          name,
          onChange,
          onBlur,
        })
      }
      return child
    })

    return (
      <div role="radiogroup" ref={ref} className="flex-col" {...props}>
        {label && <Label name={name}>{label}</Label>}

        <div className={clsx({ 'opacity-25': disabled }, className)}>{childrenWithProps}</div>

        <Error className="mt-1 h-5" id={`${name}-error`} data-testid={`${name}-error`}>
          {error?.message}
        </Error>
      </div>
    )
  },
)

export type RadioProps = InputHTMLAttributes<HTMLInputElement> & {
  readonly label?: string
  readonly labelClass?: string
  readonly crossOrigin?: '' | 'anonymous' | 'use-credentials'
}

export const Radio: FC<RadioProps> = ({ label, labelClass, children, ...props }) => {
  const labelContent = label || children
  return (
    <label
      className={clsx(
        { 'opacity-25': props.disabled },
        'my-2 mr-4 inline-flex h-5 cursor-pointer items-center',
      )}
    >
      <input
        type="radio"
        className="cursor-pointer text-orange-500 focus:outline-none focus:ring-orange-500"
        {...props}
      />
      {labelContent && <span className={clsx('ml-2', labelClass)}>{labelContent}</span>}
    </label>
  )
}
