import { FC } from 'react'
import { Dialog } from 'ui/components/containers/Dialog2'
import { PlatformPrompt } from 'ui/components/platform'
import { LeaveConfirmationOverlay } from './LeaveConfirmationOverlay'

interface LeaveConfirmationDialogProps {
  isDirty: boolean
  onSave: () => Promise<void>
  onLeave: () => void
}

export const LeaveConfirmationDialog: FC<LeaveConfirmationDialogProps> = ({
  isDirty,
  onSave,
  onLeave,
}) => {
  return (
    <PlatformPrompt when={isDirty}>
      {({ cancelNavigation, proceedWithoutAction, executeAndProceed }) => (
        <Dialog title="" open={true} onClose={cancelNavigation}>
          <LeaveConfirmationOverlay
            onProceed={() => {
              proceedWithoutAction(onLeave)
            }}
            onSaveAndProceed={() => {
              executeAndProceed(onSave)
            }}
          />
        </Dialog>
      )}
    </PlatformPrompt>
  )
}
