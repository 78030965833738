// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component

import { MutableRefObject, useEffect } from 'react'

export const useOutsideClick = (
  refs: MutableRefObject<HTMLElement>[],
  callback: () => void,
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isInsideClick = refs.some(
        (ref) => ref.current && ref.current.contains(event.target as Node),
      )
      if (!isInsideClick) {
        callback()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [...refs])
}
