import {
  ChangeEventHandler,
  ClipboardEventHandler,
  ComponentProps,
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  useState,
} from 'react'
import { Combobox } from '@headlessui/react'
import { PlusSmallIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { uniq, take } from 'lodash'
import { Button } from 'ui/src/components/forms'
import { Tag } from './Tag'
import { TagAutocompleteOptions } from './TagAutocompleteOptions'

export interface TagAutocompleteInputProps extends ComponentProps<'div'> {
  readonly name: string
  readonly selectedTags?: string[]
  readonly autocompleteTags?: string[]
  readonly placeholder?: string
  readonly inputValidationError?: string
  readonly selectedTagsValidationsError?: string
  readonly disabled?: boolean
  readonly submitKeyCodes?: string[]
  readonly removeOnBackspace?: boolean
  readonly onPasteHandler?: (value: string) => string[]
  readonly setInputValidationError?: (error: string) => void
  readonly onSelectedTagsChange: (value: string[]) => void
  readonly onChange?: ChangeEventHandler<HTMLInputElement>

  /**
   * Validates a value before turning it into a tag.
   * Returns a string representing an error if the value is invalid.
   * Otherwise returns null.
   */
  readonly validate?: (value: string) => string | null
}

export const TagAutocompleteInput: FC<TagAutocompleteInputProps> = ({
  className,
  name,
  autocompleteTags = [],
  placeholder,
  selectedTags = [],
  inputValidationError,
  selectedTagsValidationsError,
  disabled,
  submitKeyCodes = ['Enter', 'Tab'],
  removeOnBackspace = true,
  onPasteHandler,
  onSelectedTagsChange,
  setInputValidationError,
  onChange,

  validate,
}) => {
  const [inputValue, setInputValue] = useState<string>('')

  const hasError = !!inputValidationError || !!selectedTagsValidationsError

  const filterAutocompleteTags = () => {
    const uniqOptions = uniq(autocompleteTags)

    const notAddedOptions = uniqOptions.filter((option) => !selectedTags?.includes(option))

    const searchedByQueryOptions = notAddedOptions.filter((option) => {
      if (!inputValue) {
        return true
      }
      return option
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(inputValue.toLowerCase().replace(/\s+/g, ''))
    })

    const truncatedOptions = take(searchedByQueryOptions, 5)

    return truncatedOptions
  }

  const hasAutocomplete = !!autocompleteTags?.length

  const filteredAutocompleteTags = filterAutocompleteTags()

  const hasFilteredAutocomplete = !!filteredAutocompleteTags?.length

  const preventAddTag = !inputValue || !!inputValidationError || hasFilteredAutocomplete

  // handlers

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const tag = e.target.value || ''
    const inputValidationError = validateTag(tag)
    setInputValue(tag)
    setInputValidationError?.(inputValidationError)
    onChange?.(e)
  }

  const handleOnSelectedTagsChange = (tags: string[]) => {
    onSelectedTagsChange(tags)
    setInputValue('')
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (submitKeyCodes.includes(e.code) && !hasFilteredAutocomplete) {
      if (!preventAddTag) {
        addTag(inputValue)
      }
      e.preventDefault()
    }

    if (removeOnBackspace && e.code === 'Backspace' && !inputValue && selectedTags?.length > 0) {
      removeLastTag()
      e.preventDefault()
    }
  }

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = () => {
    if (!preventAddTag) {
      addTag(inputValue)
    } else if (hasAutocomplete) {
      setInputValue('')
      setInputValidationError?.(null)
    }
  }

  const handleOnPaste: ClipboardEventHandler = (e) => {
    if (onPasteHandler) {
      const rawString = e.clipboardData?.getData('text')
      const parsedTags = onPasteHandler(rawString)

      if (parsedTags?.length > 0) {
        const newTags = parsedTags.filter((t) => !selectedTags.includes(t))
        onSelectedTagsChange([...selectedTags, ...uniq(newTags)])
      }

      e.preventDefault()
    }
  }

  const validateTag = (tag: string) => {
    if (!tag) {
      return null
    }
    let inputValidationError = null
    if (validate) {
      inputValidationError ||= validate(tag)
    }
    if (selectedTags.includes(tag)) {
      inputValidationError ||= 'Duplicated entries are not allowed'
    }
    return inputValidationError
  }

  // actions

  const addTag = (tag: string) => {
    onSelectedTagsChange([...selectedTags, tag.trim()])
    setInputValue('')
    setInputValidationError?.(null)
  }

  const removeTag = (tagToRemove: string) => {
    onSelectedTagsChange(selectedTags.filter((tag) => tag !== tagToRemove))
  }

  const removeLastTag = () => {
    onSelectedTagsChange(selectedTags.slice(0, selectedTags.length - 1))
  }

  return (
    <div className={clsx('flex w-full flex-col items-center gap-2 sm:flex-row', className)}>
      <div className="w-full">
        <Combobox
          value={selectedTags}
          onChange={handleOnSelectedTagsChange}
          disabled={disabled}
          multiple
        >
          {({ open }) => {
            return (
              <div className="relative">
                <div
                  className={clsx(
                    'bg-white',
                    'relative flex w-full flex-wrap gap-x-1 gap-y-2 px-3 py-2', // box model
                    'border-taupe-600 rounded-md border shadow-sm', // border and shadow
                    'hover:border-taupe-700', // hover
                    'focus-within:!border-navy-500', // focus
                    'cursor-default overflow-hidden',
                    hasError &&
                      'border-pure-red hover:border-pure-red focus-within:!border-pure-red',
                  )}
                >
                  {selectedTags.map((selectedTag) => (
                    <Tag key={selectedTag} tag={selectedTag} onRemove={removeTag} />
                  ))}
                  <Combobox.Input
                    className={clsx(
                      'inline-block grow px-0 py-0', // box model
                      'text-sm font-normal leading-5', // text
                      'border-none focus:ring-0', // border,
                    )}
                    value={inputValue}
                    data-testid={`combobox-${name}`}
                    placeholder={placeholder}
                    onKeyDown={handleKeyDown}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onPaste={handleOnPaste}
                  />
                </div>
                {hasAutocomplete && (
                  <TagAutocompleteOptions
                    open={open && inputValue?.length > 0}
                    options={filteredAutocompleteTags}
                  />
                )}
              </div>
            )
          }}
        </Combobox>
      </div>
      {!hasAutocomplete && (
        <Button
          className="w-full shrink-0 grow-0 sm:w-auto"
          uiType={!hasError && !preventAddTag ? 'secondary' : 'disabled'}
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          <span className="sm:justify-left flex justify-center">
            <PlusSmallIcon className="-ml-2 hidden h-5 w-5 sm:inline" />
            <span className="text-sm font-medium leading-5">Add</span>
          </span>
        </Button>
      )}
    </div>
  )
}
