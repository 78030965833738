import { add, isBefore, startOfToday } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'

export function formatDateInUTC(date: Date, fmt: string): string {
  return format(utcToZonedTime(date, 'UTC'), fmt, { timeZone: 'UTC' })
}

export function formatDateStringInUTC(dateString: string, fmt: string): string {
  return format(utcToZonedTime(new Date(dateString), 'UTC'), fmt, { timeZone: 'UTC' })
}

export function formatFullDate(date: Date | string | number, withTime = false): string {
  date = new Date(date)
  const yearAgo = add(startOfToday(), { years: -1 })
  const dateFormat = isBefore(date, yearAgo) ? 'MMM d, yyyy' : 'MMM d'
  const timeFormat = withTime ? ', h:mmaaa' : ''
  return format(date, `${dateFormat}${timeFormat}`)
}
