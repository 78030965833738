import { FC, useState } from 'react'
import Link from 'next/link'
import { useSession } from 'auth'
import clsx from 'clsx'
import { getInitials, useUserNavigation } from './userNavigation'

const UserMenuPopup: FC = () => {
  const { user: currentUser } = useSession()
  const [initials] = useState(() => getInitials(currentUser))
  const userNavigation = useUserNavigation()

  return (
    <>
      <div className="max-w-5xl mx-auto px-4 flex items-center sm:px-6">
        <span className="h-10 w-10 rounded-full flex-shrink-0 flex items-center justify-center bg-taupe-200">
          <span className="text-sm font-brand lining-nums font-medium leading-none text-deep-teal-500">
            {initials}
          </span>
        </span>

        <div className="ml-3">
          <div className="text-base font-medium text-deep-teal-200 leading-5">
            {currentUser?.firstName} {currentUser?.lastName}
          </div>
          <div className="text-sm font-medium text-deep-teal-200 leading-5">
            {currentUser?.email}
          </div>
        </div>
      </div>

      <div className="mt-3 max-w-5xl mx-auto space-y-1 sm:px-4">
        {userNavigation.map((item) => (
          <Link
            key={item.name}
            href={item.href}
            onClick={item.onClick}
            className={clsx(
              'block relative py-2.5 px-4 sm:px-6 text-base  font-medium ',
              item.current
                ? clsx(
                    'bg-deep-teal-600 text-white',
                    'before:block before:absolute before:w-1 before:inset-0 before:bg-orange-500',
                  )
                : 'text-deep-teal-100 hover:bg-deep-teal-600 hover:text-white',
            )}
          >
            {item.name}
          </Link>
        ))}
      </div>
    </>
  )
}
export default UserMenuPopup
