import { ComponentProps, FC } from 'react'
import { useFlag, LDFlag } from 'launchdarkly'

export const MemberSummaryLoading: FC<ComponentProps<'div'>> = () => {
  const enableMembersDiscoveryLink = useFlag(LDFlag.EnableMemberDiscoveryNavigation)

  return (
    <div className="flex animate-pulse flex-col gap-y-4">
      <div className="flex gap-x-3">
        <div className="bg-deep-teal-400 h-12 w-12 shrink-0 rounded-full" />
        <div className="flex w-full flex-col justify-center gap-y-2">
          <div className="bg-deep-teal-400 h-5 w-1/2" />
          <div className="bg-deep-teal-400 h-4 w-2/3" />
        </div>
      </div>
      <div className="bg-deep-teal-400 h-[50px] w-full rounded-lg" />
      {enableMembersDiscoveryLink && (
        <div className="flex justify-center">
          <div className="bg-deep-teal-400 h-4 w-1/2" />
        </div>
      )}
    </div>
  )
}
