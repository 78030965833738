import { ComponentProps, FC } from 'react'
import { Popover } from '@headlessui/react'
import clsx from 'clsx'

export interface BaseToolbarContentProps {
  open: boolean
}

export interface BaseToolbarProps extends ComponentProps<'div'> {
  readonly content?: FC<BaseToolbarContentProps>
  readonly popoverContent?: FC<BaseToolbarContentProps>
}

export const BaseToolbar: FC<BaseToolbarProps> = ({
  className,
  content: Content,
  popoverContent: PopoverContent,
}) => {
  return (
    <>
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Popover
        as="nav"
        className={({ open }) =>
          clsx(
            open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
            'bg-deep-teal-500 shadow md:static md:overflow-y-visible',
            className,
          )
        }
      >
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative h-16 flex items-center">
                {Content && <Content open={open} />}
              </div>
            </div>

            <Popover.Panel as="nav" className="md:hidden" aria-label="Global">
              {PopoverContent && <PopoverContent open={open} />}
            </Popover.Panel>
          </>
        )}
      </Popover>
    </>
  )
}
