import { FC, useMemo } from 'react'
import { Event } from 'api/cms'
import { format } from 'date-fns'

export const EventCardDate: FC<{ event: Event }> = ({ event }) => {
  const [day, time] = useMemo(() => {
    if (event.eventDate) {
      const date = new Date(event.eventDate)
      return [format(date, 'd MMM'), format(date, 'h:mmaaa')]
    } else {
      return []
    }
  }, [event.eventDate])

  return (
    <div className="border-deep-teal-100 divide-deep-teal-100 divide-y divide-dashed rounded-b-lg">
      {event.eventDateTbd ? (
        <p className="text-deep-teal-400 p-1 text-center text-sm leading-5">
          {event.eventDateTbdText || 'TBD'}
        </p>
      ) : (
        <>
          <div className="text-deep-teal-300 py-1 text-center text-sm font-bold leading-5">
            {day}
          </div>
          <div className="text-deep-teal-300 py-1 text-center text-xs leading-5">{time}</div>
        </>
      )}
    </div>
  )
}
