import { useEffect, useState } from 'react'
import { createQuery, getFile, queryCommunities, runQuery } from '@amityco/ts-sdk'
import { useAmity } from './useAmity'

export const useAmityQuery = (
  createQueryFn: CreateCommunityQueryFn,
): {
  data: Amity.Community[]
  firstLoaded: boolean
  hasNextPage: boolean
  loadNextPage: () => void
  loading: boolean
} => {
  const { isConnected } = useAmity()
  const [data, setData] = useState<Amity.Community[]>([])
  const [firstLoaded, setFirstLoaded] = useState(false)
  const [nextPage, setNextPage] = useState<Amity.Page>()
  const [loading, setLoading] = useState(false)

  const loadNextPage = () => {
    if (loading) {
      return
    }

    runQuery(createQueryFn(nextPage), ({ data: newData, ...options }) => {
      if (newData) {
        setData([...data, ...newData])
        setFirstLoaded(true)
      }
      setLoading(options.loading)
      setNextPage(options.nextPage)
    })
  }

  useEffect(() => {
    if (isConnected) {
      loadNextPage()
    }
  }, [isConnected])

  return { data, firstLoaded, hasNextPage: !!nextPage, loadNextPage, loading }
}

export const useAmityFile = (fileId: string): Amity.File | undefined => {
  const [file, setFile] = useState<Amity.File>()
  const { isConnected } = useAmity()

  useEffect(() => {
    if (isConnected && fileId) {
      getFile(fileId)
        .then(({ data }) => setFile(data))
        .catch(() => {
          console.log(`Failed to fetch Amity file ${fileId}`)
          setFile(undefined)
        })
    }
  }, [isConnected, fileId])

  return file
}

/**
 * This is fake function to mimic type signature of the actual amity queries
 * we use to fetch communities from Amity. We use it to extract types
 */
/* istanbul ignore next */
const fakeCreateCommunityQueryFn = (page: Amity.Page) =>
  createQuery(queryCommunities, {
    membership: 'member',
    isDeleted: false,
    sortBy: 'lastCreated',
    page,
  })

type CreateCommunityQueryFn = typeof fakeCreateCommunityQueryFn
