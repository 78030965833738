import { useMemo } from 'react'
import { EventWithCalendarStartDate } from 'api/dto'
import { formatFullDate } from 'ui/src/lib/formatters'

export const useEventCardDate = (event: EventWithCalendarStartDate): string => {
  return useMemo(() => {
    if (event.eventDateTbd) {
      return event.eventDateTbdText || 'Date: TBD'
    }

    return formatFullDate(new Date(event.eventDate), true)
  }, [event])
}
