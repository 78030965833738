import { FC, HTMLAttributes, ReactElement } from 'react'
import clsx from 'clsx'
import { Metadata } from '../../metadata'
import { Footer } from '../Footer'
import { AppToolbar } from '../Toolbar'

export interface MainLayoutProps extends HTMLAttributes<HTMLDivElement> {
  readonly title?: string
  readonly hideToolbar?: boolean
  readonly Toolbar?: FC
  readonly banner?: ReactElement
}

export const MainLayout: FC<MainLayoutProps> = ({
  className,
  children,
  title,
  hideToolbar,
  banner,
  Toolbar,
  ...props
}) => {
  return (
    <>
      <Metadata title={title} />

      <div className="flex h-full min-h-full flex-col">
        {!hideToolbar && (Toolbar ? <Toolbar /> : <AppToolbar />)}
        {banner}
        <div className={clsx(`flex-1`, className)} {...props}>
          {children}
        </div>
        <Footer />
      </div>
    </>
  )
}
