import { FC } from 'react'
import { Avatar } from 'ui/components/content'

export const GroupAvatar: FC<{ fileUrl?: string; className?: string; displayName: string }> = ({
  fileUrl,
  displayName,
  className,
}) => {
  return (
    <div className={className}>
      {fileUrl ? (
        <Avatar className="h-full w-full" src={fileUrl} />
      ) : (
        <span className="bg-deep-teal-500 flex h-full w-full flex-shrink-0 items-center justify-center rounded-full">
          <span className="font-brand text-taupe-500 font-normal uppercase lining-nums leading-none">
            {displayName && getAbbreviation(displayName)}
          </span>
        </span>
      )}
    </div>
  )
}

const getAbbreviation = (groupName: string) =>
  groupName
    .split(' ')
    .map((word) => word[0])
    .join('')
