import { useEffect } from 'react'

// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-react/src/utils/platform.ts
const isIOS = () =>
  /iPhone/gi.test(window.navigator.platform) ||
  (/Mac/gi.test(window.navigator.platform) && window.navigator.maxTouchPoints > 0)

// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-react/src/components/dialog/dialog.tsx 94
export const useScrollLock = (enabled: boolean): void => {
  useEffect(() => {
    if (!enabled) return

    const disposables = []

    function style(node: HTMLElement, property: string, value: string) {
      const previous = node.style.getPropertyValue(property)
      Object.assign(node.style, { [property]: value })
      return disposables.push(() => {
        Object.assign(node.style, { [property]: previous })
      })
    }

    const scrollbarWidthBefore = window.innerWidth - document.documentElement.clientWidth
    style(document.documentElement, 'overflow', 'hidden')

    if (scrollbarWidthBefore > 0) {
      const scrollbarWidthAfter =
        document.documentElement.clientWidth - document.documentElement.offsetWidth
      const scrollbarWidth = scrollbarWidthBefore - scrollbarWidthAfter
      style(document.documentElement, 'paddingRight', `${scrollbarWidth}px`)
    }

    if (isIOS()) {
      const scrollPosition = window.pageYOffset
      style(document.documentElement, 'position', 'fixed')
      style(document.documentElement, 'marginTop', `-${scrollPosition}px`)
      style(document.documentElement, 'width', `100%`)
      disposables.push(() => window.scrollTo(0, scrollPosition))
    }

    return () => disposables.forEach((disposable) => disposable())
  }, [enabled])
}
